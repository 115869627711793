<template>
  <b-modal
    id="modal_finalizar_retirar_beca"
    ok-only
    size="md"
    class="p-5"
    :header-bg-variant="es_finalizar ? 'warning' : 'danger'"
    hide-footer
  >
    <template
      #modal-header
    >
      <div class="text-white">
        <strong class="float-left">
          {{ es_finalizar ? 'Finalizar Beca' : 'Retirar beca' }}
        </strong>
      </div>

    </template>
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="px-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            lg="8"
            md="7"
            sm="6"
            xs="12"
          >
            <feather-icon
              icon="UsersIcon"
              size="16"
              class="mr-2"
            />
            <strong style="">{{ becarios.length }} becarios seleccionados</strong>
          </b-col>

        </b-row>
        <br>

        <b-row>
          <b-col md="12">
            <validation-provider
              #default="validationContext"
              name="fecha_fin"
              rules="required"
            >
              <b-form-group>
                <label>Fecha Fin </label>
                <b-form-datepicker
                  v-model="becario.fecha_fin"
                  class="form-control"
                  :state="getValidationState(validationContext)"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d', disable:[{from:'1900-01-01',to:becario.fecha_inicio == null ? null : becario.fecha_inicio}]}"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  Complete el campo fecha fin

                </b-form-invalid-feedback></b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <div v-if="!es_finalizar">
          <validation-provider
            #default="validationContext"
            name="motivo_retiro"
            rules="required"
          >
            <b-form-group
              label="Motivo de retiro"
              label-for="motivo_retiro"
            >
              <b-form-textarea
                id="motivo_retiro"
                v-model="becario.motivo_retiro"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback v-if="validationContext.errors[0]">
                Este campo es requerido
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>
        <!-- Form Actions -->
        <div class="d-flex justify-content-between mt-3 mb-1">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-danger"
            size="sm"
            @click="$bvModal.hide('modal_finalizar_retirar_beca')"
          >
            <feather-icon
              icon="XIcon"
              size="14"
            />
            Cancelar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="`outline-primary`"
            class="mr-2"
            type="submit"
          >
            Guardar
            <feather-icon
              icon="SaveIcon"
              size="14"
            />
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>

import {
  BButton, BModal, BForm, BFormGroup, BFormInvalidFeedback, BCol, BRow, BFormDatepicker,
  BFormTextarea,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

export default {
  components: {

    BButton,
    BCol,
    BRow,
    BModal,
    BFormTextarea,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormDatepicker,
    ValidationObserver,
    ValidationProvider,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    servicio: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      required,
      becario: {},
      becarios: [],
      es_finalizar: false,
    }
  },
  created() {
    this.$parent.$on('finalizarRetirarBeca', this.finalizarRetirarBeca)
  },
  methods: {
    finalizarRetirarBeca(data) {
      this.es_finalizar = data.es_finalizar
      this.becarios = data.becariosSelected

      if (!this.becarios.length) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: '¡Advertencia!',
            text: 'No existe becarios seleccionados',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.$bvModal.show('modal_finalizar_retirar_beca')
    },
    onSubmit() {
      const data = this.becario
      data.becarios = this.becarios
      data.servicios_id = this.servicio
      data.es_retiro = true
      this.$emit('onSubmitmModalFinzalizarRetirar', data)
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
