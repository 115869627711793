import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getServicios() {
      return new Promise((resolve, reject) => {
        axios
          .get('/servicio-social/beca/show-servicios')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getBecarios(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/servicio-social/beca/becario', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    migrarActualizarBecarios(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/servicio-social/beca/actualizar-becario', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    actualizarPeriodoBeca(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/servicio-social/beca/periodo-becario', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
