<template>
  <div>
    <modal-asignar-periodo
      :servicio="servicio"
      @onSubmitmModalAsignar="onSubmitmModalAsignar"
    />
    <modal-finalizar-retirar-beca
      :servicio="servicio"
      @onSubmitmModalFinzalizarRetirar="onSubmitmModalFinzalizarRetirar"
    />

    <b-card no-body class="mb-0 px-2 pt-1">
      <b-row
        class="my-1 py-1 rounded"
        style="border-left:5px solid #2E9AFE; border-top:1px #E6E6E6 solid; border-right:1px #E6E6E6 solid; border-bottom:1px #E6E6E6 solid"
      >
        <b-col cols="12">
          <feather-icon
            icon="BriefcaseIcon"
            size="18"
            class="mr-50 text-info"
          />
          <span class="text-info"
            ><strong>BECARIOS BOLSA DE TRABAJO</strong>
          </span>
          <span class="ml-2 float-right">
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="migrarActualizarBecarios"
            >
              actualizar
              <feather-icon icon="RefreshCcwIcon"
            /></b-button>
          </span>
        </b-col>
      </b-row>
      <b-table
        id="my-table"
        ref="refTablaBolsaBecarios"
        class="position-relative"
        show-empty
        head-variant="light"
        hover
        small
        responsive
        :busy.sync="isBusy"
        :items="myProvider"
        :fields="tableColumns"
        :current-page="currentPage"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="
          'No hay registros que coincidan con su solicitud.'
        "
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        caption-top
        select-mode="multi"
        selectable
        :no-provider-filtering="true"
        selected-variant="success"
        @row-selected="onRowSelected"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Cargando...</strong>
          </div>
        </template>

        <template #table-caption>
          <b-row>
            <b-col xl="6" lg="6" md="6" sm="12" xs="12">
              <span>
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click="selectAllRows"
                >
                  <feather-icon icon="CheckIcon" />
                  Seleccionar todos
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click="clearSelected"
                >
                  <feather-icon icon="MinusIcon" />
                  Limpiar selección
                </b-button>
                <b-dropdown
                  variant="outline-dark"
                  class="btn-icon rounded-circle p-0"
                  size="sm"
                  right
                >
                  <template #button-content>
                    Acción masiva
                    <!-- <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="align-middle m-0 p-0"
                    /> -->
                  </template>

                  <b-dropdown-item @click="asignarPeriodo">
                    <feather-icon icon="CalendarIcon" />
                    <span class="align-middle ml-50"
                      >Asignar/Editar periodo beca</span
                    >
                  </b-dropdown-item>
                  <!-- <b-dropdown-item @click="finalizarBeca">
                    <feather-icon icon="CalendarIcon" />
                    <span class="align-middle ml-50">Finalizar beca</span>
                  </b-dropdown-item> -->
                  <b-dropdown-item @click="retirarBeca">
                    <feather-icon icon="MinusCircleIcon" class="text-danger" />
                    <span class="align-middle ml-50 text-danger"
                      >Retirar beca</span
                    >
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </b-col>
            <b-col
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              class="d-flex justify-content-end"
            >
              <div class="mr-1">
                <label>Mostrar</label>
                <b-form-select
                  v-model="perPage"
                  :options="pageOptions"
                  :clearable="false"
                  size="md"
                  style="width:auto"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>registros</label>
              </div>
              <div>
                <b-form-input
                  v-model="filter"
                  type="search"
                  placeholder="Buscar..."
                  size="md"
                />
              </div>
            </b-col>
          </b-row>
        </template>

        <!-- Column: select -->
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">
              <feather-icon icon="CheckIcon" size="30" class="text-danger" />
              <!-- &check; -->
            </span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template>
        </template>

        <!-- Column: index -->
        <template #cell(index)="row">
          {{ from + row.index }}
        </template>

        <!-- Column: becario -->
        <template #cell(becario)="row">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :variant="`light-primary`"
                :text="avatarText(recorteName(row.item.persona.apellidos))"
              />
            </template>
            <b-link class="font-weight-bold d-block text-nowrap">
              {{ row.item.persona.apellido_paterno }}
              {{ row.item.persona.apellido_materno }}
              {{ row.item.persona.nombres }}
            </b-link>
            <small class="text-primary"
              ><strong>DNI:</strong>
              {{ row.item.persona.numero_documento }}</small
            >
            <br />
            <small class="text-primary"
              ><strong>CELULAR:</strong>
              {{
                row.item.persona.celular === undefined
                  ? ""
                  : row.item.persona.celular
              }}</small
            >
          </b-media>
        </template>

        <!-- Column: etnia -->
        <template #cell(datos)="row">
          <strong>Etnia:</strong>
          <label style="font-size:12px">{{
            row.item.persona.pueblo_indigena &&
              row.item.persona.pueblo_indigena.nombre
          }}</label>
          <br />
          <strong>EAP:</strong>
          <label style="font-size:12px">{{ row.item.escuela.nombre }}</label>
        </template>

        <template #cell(fecha_inicio)="row">
          <span v-if="row.item.periodo_actual.fecha_inicio">
            {{
              new Intl.DateTimeFormat("es-PE", {
                month: "numeric",
                day: "numeric",
                year: "numeric",
              }).format(
                new Date(`${row.item.periodo_actual.fecha_inicio}T00:00:00`)
              )
            }}
          </span>
        </template>

        <template #cell(fecha_fin_salida)="row">
          <span v-if="row.item.periodo_actual.fecha_fin">
            {{
              new Intl.DateTimeFormat("es-PE", {
                month: "numeric",
                day: "numeric",
                year: "numeric",
              }).format(
                new Date(`${row.item.periodo_actual.fecha_fin}T00:00:00`)
              )
            }}
          </span>
        </template>

        <!-- Column: estado -->
        <template #cell(estado)="row">
          <b-badge
            pill
            :variant="`light-${resolveEstadoVariant(row.item.estado)}`"
            class="text-capitalize"
          >
            {{ row.item.estado }}
          </b-badge>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Mostrando registros del {{ from }} al {{ to }} de un total de
              {{ totalRows }} registros</span
            >
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BSpinner,
  BFormSelect,
  BMedia,
  BAvatar,
  BBadge,
  BPagination,
  BButton,
  BLink,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
// import vSelect from 'vue-select'
import { onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import bolsaBecariosStoreModule from "./bolsaBecariosStoreModule";
import modalAsignarPeriodo from "../../comedor-universitario/becarios/ModalAsignarPeriodo.vue";
import modalFinalizarRetirarBeca from "../../comedor-universitario/becarios/ModalFinalizarRetirarBeca.vue";

export default {
  components: {
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BSpinner,
    BBadge,
    BPagination,
    BButton,
    BMedia,
    BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,

    // externs
    modalAsignarPeriodo,
    modalFinalizarRetirarBeca,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      servicio: [], // 15 es id de bolsa de trabajo
      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 500,
      pageOptions: [50, 100, 300, 500],
      sortBy: "id",
      sortDesc: false,
      // sortDirection: 'desc',
      filter: null,
      filterOn: [],
      btnActivo: 0,
      textHistorial: "Historial",
      botones: [],
      filtroServicio: [],
      selected: [],
      tableColumns: [
        { key: "selected", label: "" },
        { key: "index", label: "N°" },
        { key: "becario", label: "Becario" },
        { key: "datos", label: "Datos Becario" },
        { key: "fecha_inicio", label: "F.inicio", sortable: true },
        // { key: 'fecha_fin', label: 'F.fin (programado)', sortable: true },
        { key: "fecha_fin_salida", label: "F. salida", sortable: true },
        {
          key: "periodo_actual.ubicacion_bolsa",
          label: "Área de trabajo",
          sortable: true,
        },
        { key: "estado", label: "estado", sortable: true },
        // { key: 'acciones', label: 'acciones', sortable: true },
      ],
      becariosSelected: [],
      es_finalizar: false,
      items: [],
    };
  },

  methods: {
    actualizarTabla() {
      this.filter = null;
      this.$refs.refTablaBolsaBecarios.refresh();
    },
    async myProvider(ctx) {
      if (this.filter) {
        return [];
      }
      await this.$http.get("/servicio/BECA-BOLSA/buscar").then((res) => {
        this.servicio = [res.data.id];
      });
      // eslint-disable-next-line no-param-reassign
      ctx.servicios_id = this.servicio;
      const promise = store.dispatch(
        "bolsa-becarios-store-module/getBecarios",
        { queryParams: ctx }
      );
      return promise.then((response) => {
        const { data, total, from, to } = response.data;

        this.items = data;
        this.totalRows = total || 0;
        this.from = from || 0;
        this.to = to || 0;
        return this.items || [];
      });
    },
    recorteName(name) {
      // eslint-disable-next-line no-param-reassign
      if (name === null || name === "") {
        name = "B";
      }
      let recorte = [];
      recorte = name.split("");
      return `${recorte[0]} ${recorte[1]}`;
    },

    migrarActualizarBecarios() {
      const data = { servicios_id: this.servicio };
      store
        .dispatch("bolsa-becarios-store-module/migrarActualizarBecarios", data)
        .then(() => {
          this.actualizarTabla();
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "¡Actualización exitosa!",
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "¡Error en la actualización!",
              icon: "CheckCircleIcon",
              variant: "danger",
            },
          });
        });
    },
    asignarPeriodo() {
      this.$emit("asignarPeriodo", this.becariosSelected);
    },
    finalizarBeca() {
      this.$emit("finalizarRetirarBeca", {
        becariosSelected: this.becariosSelected,
        es_finalizar: true,
      });
      this.es_finalizar = true;
    },
    retirarBeca() {
      this.$emit("finalizarRetirarBeca", {
        becariosSelected: this.becariosSelected,
        es_finalizar: false,
      });
      this.es_finalizar = false;
    },
    // SELECCION MASIVA
    onRowSelected(items) {
      this.becariosSelected = items;
      this.becariosSelected = this.becariosSelected.map((val) => ({
        becario_id: val.id,
        convocatoria_id: val.periodo_actual.convocatoria_id,
      }));
    },
    selectAllRows() {
      this.$refs.refTablaBolsaBecarios.selectAllRows();
    },
    clearSelected() {
      this.$refs.refTablaBolsaBecarios.clearSelected();
    },

    onSubmitmModalAsignar(data) {
      store
        .dispatch("bolsa-becarios-store-module/actualizarPeriodoBeca", data)
        .then(() => {
          this.actualizarTabla();
          this.$swal({
            icon: "success",
            title: "¡Éxito!",
            text: "Periodos actualizados correctamente.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          }).then(() => {
            this.$bvModal.hide("modal_asignar_periodo");
          });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "¡Error!",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },
    onSubmitmModalFinzalizarRetirar(data) {
      store
        .dispatch("bolsa-becarios-store-module/actualizarPeriodoBeca", data)
        .then(() => {
          this.actualizarTabla();
          this.$swal({
            icon: "success",
            title: "¡Éxito!",
            text: this.es_finalizar
              ? "La beca ha finalizado correctamente."
              : "Se retiro la beca correctamente.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          }).then(() => {
            this.$bvModal.hide("modal_asignar_periodo");
          });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error!",
              text: "",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },
  },
  setup() {
    const COMEDOR_BECARIOS_APP_STORE_MODULE_NAME =
      "bolsa-becarios-store-module";

    if (!store.hasModule(COMEDOR_BECARIOS_APP_STORE_MODULE_NAME))
      store.registerModule(
        COMEDOR_BECARIOS_APP_STORE_MODULE_NAME,
        bolsaBecariosStoreModule
      );

    onUnmounted(() => {
      if (store.hasModule(COMEDOR_BECARIOS_APP_STORE_MODULE_NAME))
        store.unregisterModule(COMEDOR_BECARIOS_APP_STORE_MODULE_NAME);
    });

    const resolveEstadoVariant = (status) => {
      if (status === "pendiente") return "warning";
      if (status === "activo") return "success";
      if (status === "inactivo") return "danger";
      return "secondary";
    };
    const resolveServicioIcon = (value) => {
      if (value === "BECA-COMEDOR") return "CoffeeIcon";
      if (value === "BECA-RESIDENCIA") return "HomeIcon";
      if (value === "BECA-BOLSA") return "BriefcaseIcon";
      return "TargetIcon";
    };

    return {
      bolsaBecariosStoreModule,
      avatarText,
      // resolveServicioVariant,
      resolveServicioIcon,
      resolveEstadoVariant,
      // roleOptions,
    };
  },
};
</script>

<style lang="scss" scoped></style>
